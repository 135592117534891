// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Fira Sans BGR', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

.item-text-content div span {
    font-family: 'Fira Sans BGR', sans-serif !important;
}


/* fira-sans-Light - latin_cyrillic Light */

@font-face {
    font-family: 'Fira Sans BGR';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/FiraSansBGR-Light.eot');
    src: local('Fira Sans BGR Light'), local('FiraSans-Light'), url('/fonts/FiraSansBGR-Light.eot') format('embedded-opentype'), url('/fonts/FiraSansBGR-Light.otf') format('opentype'), url('/fonts/FiraSansBGR-Light.woff2') format('woff2'), url('/fonts/FiraSansBGR-Light.woff') format('woff'), url('/fonts/FiraSansBGR-Light.ttf') format('truetype');
}

/* fira-sans-regular - latin_cyrillic REGULAR */
@font-face {
    font-family: 'Fira Sans BGR';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/FiraSansBGR-Regular.eot');
    src: local('Fira Sans BGR Regular'), local('FiraSans-Regular'), url('/fonts/FiraSansBGR-Regular.eot') format('embedded-opentype'), url('/fonts/FiraSansBGR-Regular.otf') format('opentype'), url('/fonts/FiraSansBGR-Regular.woff2') format('woff2'), url('/fonts/FiraSansBGR-Regular.woff') format('woff'), url('/fonts/FiraSansBGR-Regular.ttf') format('truetype');
}

/* fira-sans-italic - latin_cyrillic ITALIC */
@font-face {
    font-family: 'Fira Sans BGR';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/FiraSansBGR-Italic.eot');
    src: local('Fira Sans BGR Italic'), local('FiraSans-Italic'), url('/fonts/FiraSansBGR-Italic.eot') format('embedded-opentype'), url('/fonts/FiraSansBGR-Italic.otf') format('opentype'), url('/fonts/FiraSansBGR-Italic.woff2') format('woff2'), url('/fonts/FiraSansBGR-Italic.woff') format('woff'), url('/fonts/FiraSansBGR-Italic.ttf') format('truetype');
}

/* fira-sans-500 - latin_cyrillic MEDIUM*/
@font-face {
    font-family: 'Fira Sans BGR';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/FiraSansBGR-Medium.eot');
    src: local('Fira Sans BGR Medium'), local('FiraSans-Medium'), url('/fonts/FiraSansBGR-Medium.eot') format('embedded-opentype'), url('/fonts/FiraSansBGR-Medium.otf') format('opentype'), url('/fonts/FiraSansBGR-Medium.woff2') format('woff2'), url('/fonts/FiraSansBGR-Medium.woff') format('woff'), url('/fonts/FiraSansBGR-Medium.ttf') format('truetype');
}

/* fira-sans-700 - latin_cyrillic BOLD */
@font-face {
    font-family: 'Fira Sans BGR';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/FiraSansBGR-Bold.eot');
    src: local('Fira Sans BGR Bold'), local('FiraSansBGR-Bold'), url('/fonts/FiraSansBGR-Bold.eot') format('embedded-opentype'), url('/fonts/FiraSansBGR-Bold.otf') format('opentype'), url('/fonts/FiraSansBGR-Bold.woff2') format('woff2'), url('/fonts/FiraSansBGR-Bold.woff') format('woff'), url('/fonts/FiraSansBGR-Bold.ttf') format('truetype');
}

/*
@font-face {
    font-family: 'Fira Sans';
    src: url('/fonts/FiraSansBGR-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansBGR-Regular.otf')  format('opentype'),
    url('/fonts/FiraSansBGR-Regular.otf.woff2') format('woff2'),
    url('/fonts/FiraSansBGR-Regular.ttf')  format('truetype'),
    url('/fonts/FiraSansBGR-Regular.svg#FiraSansBGR-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans-Bold';
    src: url('/fonts/FiraSansBGR-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/FiraSansBGR-Bold.otf') format('opentype'),
    url('/fonts/FiraSansBGR-Bold.otf.woff2') format('woff2'),
    url('/fonts/FiraSansBGR-Bold.ttf') format('truetype'),
    url('/fonts/FiraSansBGR-Bold.svg#Repo') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
